"use client";

import Link from "next/link";
import type { ComponentProps } from "react";
import { usePathname } from "next/navigation";

import { Logo } from "@/components/typography/Logo";
import { SocialIcons } from "@/components/typography/SocialIcons";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const Footer = ({ ...rest }: ComponentProps<"footer">) => {
  const pathname = usePathname();

  return (
    <footer
      className={cn(
        "pt-16 pb-4 relative overflow-hidden z-0",
        {
          ["bg-white"]: pathname.startsWith("/partner"),
        },
        rest.className
      )}
    >
      <div className="container max-w-7xl mx-auto px-4 md:px-8">
        <div className="flex flex-wrap justify-between">
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <Link href="/" className="mb-4" aria-label="Dialetics">
              <Logo />
            </Link>
            <SocialIcons />
          </div>

          {/* Angebot Column */}
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <p className="font-bold mb-4">Angebot</p>
            <ul className="space-y-2">
              <li>
                <Link href="/online-kurse" className="hover:underline">
                  Online Schulungen
                </Link>
              </li>
              <li>
                <Link href="/blog" className="hover:underline">
                  Wissensplattform
                </Link>
              </li>
              <li>
                <Link href="/calendar" className="hover:underline">
                  Diabetes Kalender
                </Link>
              </li>
              <li>
                <Link href="/events" className="hover:underline">
                  Events
                </Link>
              </li>
              <li>
                <Link href="/vortraege" className="hover:underline">
                  Mentorship
                </Link>
              </li>
            </ul>
            <p className="font-bold mt-6 mb-4">Dialetics</p>
            <ul className="space-y-2">
              <li>
                <Link href="/about" className="hover:underline">
                  Über uns
                </Link>
              </li>
              <li>
                <Link href="/presse" className="hover:underline">
                  Presse
                </Link>
              </li>
              <li>
                <Link href="/newsletter" className="hover:underline">
                  Newsletter
                </Link>
              </li>
              <li>
                <Link href="/kontakt" className="hover:underline">
                  Kontakt
                </Link>
              </li>
            </ul>
          </div>

          {/* Kooperation and Legals Column */}
          <div className="w-full md:w-1/4 mb-8 md:mb-0">
            <p className="font-bold mb-4">Kooperation</p>
            <ul className="space-y-2">
              <li>
                <Link href="/partner/hcp" className="hover:underline">
                  HCP Program
                </Link>
              </li>
              <li>
                <Link href="/partner/b2b" className="hover:underline">
                  B2B Kooperationen
                </Link>
              </li>
              <li>
                <Link href="/vortraege" className="hover:underline">
                  Vorträge & Events
                </Link>
              </li>
            </ul>
            <p className="font-bold mt-6 mb-4">Legals</p>
            <ul className="space-y-2">
              <li>
                <Link href="/impressum" className="hover:underline">
                  Impressum
                </Link>
              </li>
              <li>
                <Link href="/datenschutz" className="hover:underline">
                  Datenschutz
                </Link>
              </li>
              <li>
                <Link href="/widerruf" className="hover:underline">
                  Widerruf
                </Link>
              </li>
              <li>
                <Link href="/agb" className="hover:underline">
                  AGB
                </Link>
              </li>
            </ul>
          </div>

          {/* CTA Box */}
          <div className="w-full md:w-1/4">
            <div className="bg-white p-6 rounded-2xl shadow-md text-center">
              <p className="text-2xl font-bold mb-2">Jetzt mitmachen</p>
              <p className="mb-4">
                Erhalte kostenlos Zugriff auf alle Kurse und Artikel.
              </p>
              <Link href="/onboarding/user" className="mb-4">
                <Button className="rounded-full ">Kostenlos anmelden</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Decorative Wave */}
      <div className="absolute -bottom-16 left-0 right-0 bg-footer-pattern bg-no-repeat bg-center w-full h-60 -z-10"></div>
    </footer>
  );
};

export { Footer };
