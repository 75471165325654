import type { FormEvent, ComponentProps } from "react";
import { Search } from "lucide-react";
import { useRouter } from "next/navigation";
import { cn } from "@/lib/utils";

type SearchFormProps = {
  onFocus?: () => void;
  onBlur?: () => void;
  fullWidth?: boolean;
} & ComponentProps<"form">;

const SearchForm = ({
  onFocus,
  onBlur,
  fullWidth = false,
  ...rest
}: SearchFormProps) => {
  const router = useRouter();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const queryTerm = formData.get("query");
    router.push("/search?q=" + queryTerm);
  };

  return (
    <form
      method="get"
      action="/search"
      onSubmit={handleSubmit}
      className={cn("relative", rest.className)}
    >
      <input
        type="search"
        name="query"
        placeholder="Suche"
        className={cn(
          "pl-8 md:pr-2 py-1 h-10 w-10 md:w-auto border border-transparent rounded-full transition-all duration-300 ease-in-out focus:w-auto md:focus:w-auto",
          { ["w-full md:w-full focus:w-full md:focus:w-full"]: fullWidth }
        )}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-black w-4 h-4" />
    </form>
  );
};

export { SearchForm };
